<template>
   <div class="statistics-wrapper">
      <div class="flex-box-column statistics-cover">
         <div class="hd">
            <van-nav-bar left-arrow safe-area-inset-top title="数据简报" class="search" @click-left="back">
            </van-nav-bar>
         </div>
         <div class="bd">
            <!-- 顶部跳转链接 -->
            <div class="statiscs-link-list">
               <div class="statiscs-link-item house" @click="goLink({path:'/estate_development/statistics/detail/house',query:{dateType:1}})">
                  <p class="icon"><van-icon name="system-house-s" /></p>
                  <p class="title">楼盘数据</p>
               </div>
               <div class="statiscs-link-item staff" @click="goLink({path:'/estate_development/statistics/detail/staff',query:{dateType:1}})">
                  <p class="icon"><van-icon name="system-tuozhan" /></p>
                  <p class="title">拓展口数据</p>
               </div>
            </div>
            <!-- 数据列表 -->
            <div class="statiscs-data-list">
               <div class="statiscs-data-item">
                  <div class="icon bb"></div>
                  <div class="content">
                     <p>累计报备</p>
                     <p>今日报备{{dayAll['baobeiNum'] || 0}}条</p>
                  </div>
                  <div class="number">{{all['baobeiNum'] || 0}}</div>
               </div>
               <div class="statiscs-data-item">
                  <div class="icon df"></div>
                  <div class="content">
                     <p>累计到访</p>
                     <p>今日到访{{dayAll['visit'] || 0}}条</p>
                  </div>
                  <div class="number">{{all['visit'] || 0}}</div>
               </div>  
               <div class="statiscs-data-item">
                  <div class="icon cj"></div>
                  <div class="content">
                     <p>累计成交</p>
                     <p>今日成交{{dayAll['makeBargain'] || 0}}组</p>
                  </div>
                  <div class="number">{{all['makeBargain'] || 0}}</div>
               </div>
               <div class="statiscs-data-item">
                  <div class="icon ytotal"></div>
                  <div class="content">
                     <p>累计佣金总额</p>
                     <p>今日收佣{{dayAll['yongjin'] || 0}}元</p>
                  </div>
                  <div class="number">{{all['yongjin'] || 0}}</div>
               </div>
               <div class="statiscs-data-item">
                  <div class="icon total"></div>
                  <div class="content">
                     <p>累计成交金额</p>
                     <p>今日成交{{dayAll['chengjiaoer'] || 0}}元</p>
                  </div>
                  <div class="number">{{all['chengjiaoer'] || 0}}</div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
/**
 * 统计页面
 */
import backMixin from "@/mixins/back";
import api from "@/api";
export default {

   mixins:[backMixin],

   data(){

      return {


         statiscsData:{}


      }

   },

   computed:{

      all(){

         return this.statiscsData?.all ? this.statiscsData.all : {};

      },

      dayAll(){

         return this.statiscsData?.dayAll ? this.statiscsData.dayAll : {};

      }

   },

   methods:{


      //初始化方法

      init(){

         this.updateData();

      },

      //获取统计数据

      updateData(){

         this.$toast.loading({

            message:"加载中...",

            duration:0,

            forbidClick:true,

            overlay:true

         });

         api.getExtensionToReportStatistics({

            success:(res)=>{

               if(res.type == 200){

                  this.statiscsData = res.data;

               }else{
                  
                  this.$dialog.alert({

                     title:'数据异常',

                     message:res.msg || '获取统计数据失败，请稍后重试',
                     
                     theme: 'round-button'

                  }).then(()=>{});

               }

            },

            error:(err)=>{

               console.log(err);

               this.$dialog.alert({

                  title:'数据异常',

                  message:'获取统计数据失败，数据异常，请稍后重试',

                  theme: 'round-button'

               }).then(()=>{});

            },

            complete: ()=>{

               this.$toast.clear();
               
            }

         })

      },

      //跳转

      goLink(path){

         this.$router.push(path)

      }


   },


   mounted(){

      this.init();

   }

}
</script>

<style scoped lang="less">
.statistics-wrapper{

   height:100%;

   .statistics-cover{

      height:100%;


   }

   .hd{

      .nav-right{

         color:#00a0e4;

         font-size:14px;

         .van-icon{

            color:#00a0e4;

            padding-left:2px;

            position: relative;

            top:1px;

         }

      }

   }

   .bd{

      flex:1;

      height:0;

      position: relative;

      overflow: hidden;

      overflow-y:auto;

      padding:10px 15px;

      background:#efefef;

      .statiscs-link-list{

         display: flex;
         
         justify-content: space-between;

         flex-wrap: wrap;

         .statiscs-link-item{

            flex:1;

            border-radius: 5px;

            margin-bottom:10px;

            color:#fff;

            display: flex;

            flex-direction: column;

            justify-content: center;

            align-items: center;

            padding: 15px 0;

            transition: opacity .3s;

            &:active{

               opacity: .6;

            }

            .icon{

               .van-icon{font-size:32px};

               height:36px;

               overflow: hidden;

            }

            .title{

               font-size:16px;

            }

            &.house{

               background: #27b5fe;

            }

            &.staff{

               background: #62d5c3;

            }

            &:nth-child(odd){

               margin-right:5px;

            }

            &:nth-child(even){

               margin-left:5px;

            }

         }

      }

      .statiscs-data-list{

         .statiscs-data-item{

            display: flex;

            align-items: center;

            justify-content: space-between;

            padding: 15px;

            background:#fff;

            box-shadow: 0 5px 10px rgba(0,0,0,.08);

            border-radius:5px;

            margin-bottom:10px;

            .icon{

               background:url('../../assets/images/statistics_icon_sprites.png') no-repeat;

               background-size:170px 32px;

               width: 32px;

               height:32px;

               &.bb{

                  background-position:-103.5px 0;

               }

               &.df{

                  background-position:0 0;

               }

               &.cj{

                  background-position: -138px 0;

               }

               &.ytotal{

                  background-position:-34.5px 0;

               }    
               
               &.total{

                  background-position:-69px 0;

               }

            }

            .content{

               flex:1;

               padding-left:20px;

               p:first-child{

                  font-size:16px;

                  color:#666666;

               }

               p:nth-child(2){

                  font-size:14px;

                  color:rgb(250, 85, 85);

                  padding-top:5px;

               }

            }

            .number{

               font-size:26px;

               color:#eb6069;

            }
            

         }

      }

   }

}
</style>
